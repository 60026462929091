body {
  --font-size: 20px;
  --font-size-large: 28px;
  --font-size-small: 18px;
  --font-size-xsmall: 16px;
  --font-size-tiny: 14px;
  --font-weight: 400;
  --font-weight-light: 300;
  --padding: 24px;
  --padding-small: 12px;
  --padding-xsmall: 6px;
  --padding-tiny: 3px;
  --padding-large: 48px;
  --color-brand: #d0b181;
  --color-up: #5fe9a7;
  --color-down: #fe8d7e;
  --color-button: #878a94;
  --color-error: #ff6f43;
  --color-1: #ffffff;
  --color-2: #aaa;
  --color-3: #888;
  --color-4: #6c6c6c;
  --color-5: #6c6c6c8a;
  --color-6: #2e2f33;
  --color-7: #1d1e21;
  --color-8: #121213;

  background: #353a4d;
  color: var(--color-1);
  margin: 0;
  font-family: "Roboto", "Helvetica Neue", sans-serif;
  font-size: 20px;
  user-select: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}

* {
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: inherit;
}

h1 {
  color: var(--color-1);
  font-size: var(--font-size);
  text-align: center;
}

h2 {
  color: var(--color-2);
  font-size: var(--font-size-tiny);
  text-align: center;
  text-transform: uppercase;
  margin-top: var(--padding);
  letter-spacing: 3px;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
}

button {
  background: none;
  border: 1px solid var(--color-4);
  border-radius: 4px;
  color: inherit;
  font-size: inherit;
  line-height: inherit;
  padding: 10px 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
}

button > svg {
  margin-right: var(--padding-xsmall);
}

button.icon-button {
  border: none;
  font-size: inherit;
}

input {
  background: var(--color-6);
  border: none;
  border-radius: 5px;
  color: var(--color-1);
  outline: none;
  padding: 10px 12px;
  font-size: inherit;
  transition: 0.2s all;
}

input:focus {
  box-shadow: 0 0 0 2px var(--color-brand);
}

input[disabled] {
  background: none;
  padding-left: 0;
  padding-right: 0;
}

form label {
  display: flex;
  font-size: var(--font-size-xsmall);
  flex-direction: column;
  justify-content: center;
  text-transform: uppercase;
}

form label > input {
  font-size: var(--font-size);
  margin-top: var(--padding-xsmall);
  width: 100%;
}
