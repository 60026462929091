@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translate(0, 50px);
  }

  to {
    opacity: 1;
    transform: translate(0, 0);
  }
}

.fade-in {
  animation: fadeIn 0.5s ease;
}

.center {
  text-align: center;
}

.font-size-large {
  font-size: var(--font-size-large);
}

.font-size-small {
  font-size: var(--font-size-small);
}

.font-size-xsmall {
  font-size: var(--font-size-xsmall);
}

.font-size-tiny {
  font-size: var(--font-size-tiny);
}

.color-brand {
  color: var(--color-brand);
}

.color-up {
  color: var(--color-up);
}

.color-down {
  color: var(--color-down);
}

.color-1 {
  color: var(--color-1);
}

.color-2 {
  color: var(--color-2);
}

.color-3 {
  color: var(--color-3);
}

.color-4 {
  color: var(--color-4);
}

.bg-4 {
  background: var(--color-4);
}

.bg-5 {
  background: var(--color-6);
}

.shadow {
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3);
}

.shadow-inset {
  box-shadow: inset 0 6px 6px -6px #000;
}

.padding {
  padding: var(--padding);
}

.h-expand {
  margin-left: calc(-1 * var(--padding));
  margin-right: calc(-1 * var(--padding));
  padding-left: var(--padding);
  padding-right: var(--padding);
}

.margin-top {
  margin-top: var(--padding);
}

.margin-top-small {
  margin-top: var(--padding-small);
}

.margin-top-xsmall {
  margin-top: var(--padding-xsmall);
}

.margin-top-tiny {
  margin-top: var(--padding-tiny);
}

.v-grid {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--padding);
}

.v-grid-small {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--padding-small);
}

.v-grid-xsmall {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--padding-xsmall);
}

.v-center {
  display: flex;
  align-items: center;
}

.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.row > * + * {
  margin-left: var(--padding);
}

.row > :last-child {
  text-align: right;
}

.row-right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.row-right > * + * {
  margin-left: var(--padding);
}

.sections {
  display: grid;
  grid-auto-flow: row;
  grid-row-gap: var(--padding);
}

.sections > * {
  box-shadow: inset 0 6px 6px -6px #000;
  padding: var(--padding);
  padding-bottom: 0;
}

.sections > *:first-child {
  box-shadow: none;
  padding-top: 0;
}

@keyframes AnimateIn {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-in {
  animation: AnimateIn 0.2s ease-out;
}

.transition {
  transition: 0.2s all ease-out;
}

.no-wrap {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
